import * as Yup from 'yup';
import Reference from 'yup/lib/Reference';

import { VALIDATION_REQUIRED_MSG, STOP_ACTIVITY_TYPES } from 'enums';

const maxCharWarning = (num: number) => `Must be below ${num} characters`;

const getValidationDate = (date: string) => {
  const desiredDate = date ? new Date(date) : new Date(Date.now() - 86400000);
  desiredDate.setHours(0, 0, 0, 0);
  return desiredDate;
};

export const getValidationSchema = (reeferEquipmentTypeId: number, quoteCreatedAt: string) => Yup.object({
  customer: Yup.number().required(VALIDATION_REQUIRED_MSG),
  pickupDate: Yup.date()
    .typeError('Please enter a valid date')
    .max(Yup.ref('deliveryDate'), 'Pickup Date cannot be after delivery date'),
  deliveryDate: Yup.date()
    .typeError('Please enter a valid date')
    .min(Yup.ref('pickupDate'), 'Delivery Date cannot be before pickup date'),
  effectiveDate: Yup.date()
    .min(getValidationDate(quoteCreatedAt), 'Effective Date must be in the future')
    .max(Yup.ref('expirationDate'), 'Effective Date cannot be after Expiration date'),
  expirationDate: Yup.date().nullable()
    .min(Yup.ref('effectiveDate'), 'Expiration Date cannot be before Effective Date'),
  equipment: Yup.number().required(VALIDATION_REQUIRED_MSG),
  weight: Yup.number().nullable().min(0, 'Weight must be a positive number'),
  commodity: Yup.string().max(50, maxCharWarning(32)),
  team: Yup.bool(),
  notes: Yup.string(),
  quoteRequestSource: Yup.number().nullable(),
  shipper_quote_id: Yup.string().max(32, maxCharWarning(32)).nullable(),
  custom_fields: Yup.object().nullable().when('equipment', {
    is: reeferEquipmentTypeId,
    then: Yup.object({
      min_temp: Yup.number()
        .moreThan(-30, 'Min Temp must be above -30')
        .lessThan(Yup.ref('max_temp') as Reference<number>, 'Min Temp should be lower than Max Temp'),
      max_temp: Yup.number()
        .lessThan(60, 'Max Temp must be below 60')
        .moreThan(Yup.ref('min_temp') as Reference<number>, 'Max Temp should be greater than Min Temp')
    }).nullable()
  }),
  lane: Yup.object().nullable().shape({
    stops: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number(),
          activity_type: Yup.number(),
          address: Yup.string().required(VALIDATION_REQUIRED_MSG)
        }).test({
          name: 'selectError',
          message: 'An error occured while selecting the location.',
          test: val => Object.values(val).every(element => element !== undefined)
        })
      )
      .min(2, 'There must be at least two stopping points')
      .test({
        name: 'firstAndLast',
        message: 'First location must be of type pickup and last location must be of type delivery',
        test: val =>
          val.every(
            ({ activity_type }, index) => {
              if (index === 0) {
                return activity_type === STOP_ACTIVITY_TYPES.PICKUP;
              }
              if (index === val.length - 1) {
                return activity_type === STOP_ACTIVITY_TYPES.DELIVERY;
              }
              return true;
            }
          )
      })
  })
});
